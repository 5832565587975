import { Component, TemplateRef, ViewChild, AfterViewInit, Output, EventEmitter} from '@angular/core';
import { Headers, Response, Http, RequestOptions } from '@angular/http';
import { IPage } from './questions/app.questions.generic';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';

export interface IQuestions {
  package?: 'Basic'|'Complete';
  amp?: 'Versterker'|'Sonos' | string;
  connection?: '3.5mm jack' | 'tulp' | 'speakon' | 'Other';
  electro?: 'Ja' | 'Nee';
  wiredinternet?: 'Ja binnen 2 meter' |
  'De aansluiting is verder weg (tot 15 meter) maar bereikbaar zonder te boren '|
  'De aansluiting is verder weg (15+ meter) maar bereikbaar zonder te boren '|
  'De aansluiting is verder weg (15+ meter) en er moet geboord worden' |
  'Geen aansluiting';
  wifiinternet?: 'Ja' | 'Nee';
  wall?: 'Beton' | 'Gips' | 'Hout' | string;
  [str: string]: any;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements AfterViewInit {

  @ViewChild('resulthardconnection') resulthardconnection: TemplateRef<any>;
  @ViewChild('resulthardelectro') resulthardelectro: TemplateRef<any>;
  @ViewChild('resultsucces') resultsucces: TemplateRef<any>;
  result: string;
  title = 'mkbmuziekform';
  questions: any[];
  question: IQuestions = {
  };
  status: 'incomplete' | 'sending' | 'send' = 'incomplete';
  @Output() statusChanged = new EventEmitter<string>();
  constructor(private http: Http) {}
  ngAfterViewInit() {
    this.getQuestions();
  }
  onNext(page: IPage, t) {
    const next = page.hasOwnProperty('next') ? (page as {next: any}).next() : '';
    t.select(next ? next.title : '');
  }

  beforeChange($event: NgbTabChangeEvent, t: any) {
    const obj =  this.getQuestions()
    .find(p => p.title === $event.nextId);
    if (obj.type === 'submit') {
      this.submit();
    }
  }

  getQuestions(): IPage[] {
    const greeting = { 
      type: 'open',
      title: 'Welkom', // 1
      headquestion: 'Welkom bij Zorgscherm',
      subquestion: 'Om Zorgscherm bij u goed op te leveren, vragen wij u kort om hulp bij de voorbereiding. Hierna stellen wij enkele vragen ten behoeve van de aansluitingen, administratie en planning. Zo kunt u hier uw gewenste dag en tijd aangeven waarop wij mogen langskomen. Komt u er niet uit? Wij doorlopen graag samen de vragenlijst. Belt u ons dan op + 31 (0)348 447095. Alvast dank voor het invullen van de vragenlijst.',
      paths: () => {
        return [
          questionCompany
        ] ;
      },
      next: () => {
        return questionCompany
      }
    };
    const questionCompany: IPage = {
      type: 'open',
      title: 'Bedrijfsnaam', // 1
      headquestion: 'Vul uw bedrijfsgegevens in',
      field: 'company',
      options: [
        'bedrijfsnaam'
      ],
      paths: () => {
        return [
          questionPackage
        ] ;
      },
      next: () => {
        if (!this.question.company) {
          this.question.company = {};
        }
        if (this.question.company['bedrijfsnaam']) {
          return questionPackage;
        }
      }
    };
    const questionPackage: IPage = {
      type: 'multible',
      title: 'Pakket', // 1
      headquestion: 'Kies uw pakket',
      field: 'package',
      options: [
        {
          image: 'assets/zorgscherm-instalatie-basic.png',
          name: 'Zorgscherm installatie zonder tv ',
          value: 'Basic'
        },
        {
          image: 'assets/zorgscherm-instalatie-full.png',
          name: 'Zorgscherm met tv installatie',
          value: 'Complete'
        }
      ],
      paths: () => {
        return [
          questionTV,
          questionHDMI
        ] ;
      },
      next: () => {
        switch (this.question.package) {
          case 'Complete':
            return questionTV
            ;
          case 'Basic':
            return questionHDMI;

          default:
            return;
        }
      }
    };
    const questionTV: IPage = {
      type: 'multible',
      title: 'TV',
      headquestion: 'Wat voor TV wilt u?',
      field: 'tv',
      options: [
        {
          image: 'assets/zorgscherm-scherm-32.png',
          name: 'Een 32 inch HD TV  (- € 25 excl. BTW)(70,8cmx 39,9cm)',
          value: '32 inch'
        }, {
          image: 'assets/zorgscherm-scherm-40.png',
          name: 'Een 40 inch HD TV (standaard & aanbevolen) (88,6cm x 49,8cm)',
          value: '40 inch'
        },
        {
          image: 'assets/zorgscherm-scherm-43.png',
          name: 'Een 43 inch HD TV (+ €75 excl. BTW(95,2cmx 53,6cm)',
          value: '43 inch'
        },
        {
          image: 'assets/zorgscherm-scherm-50.png',
          name: 'Een 50 inch HD TV (+ € 150 excl. BTW)(110,7cmx 62,3cm)',
          value: '50 inch'
        },
        {
          image: 'assets/zorgscherm-scherm-ander-formaat.png',
          name: 'Ander formaat',
          value: 'Ander'
        }
      ],
      paths: () => [],
      next: () => {
        if (this.question.tv == 'Ander') {
          return questionSize;
        }
        else {
          return questionBeugel;
        }
      }
    };
    /*const questionSize: IPage = {
      type: 'open',
      title: 'Ander formaat TV',
      headquestion: 'Welk formaat tv wilt u?',
      field: 'size',
      options: [
        'size'
      ],
      paths: () => [questionBeugel],
      next: () => {
        return questionBeugel;
      }
    };*/
    const questionSize: IPage = {
      
      type: 'open',
      title: 'Ander formaat TV',
      headquestion: 'Welk formaat TV wilt u?',
      field: "size",
      options:[
        "TV grootte"
      ],
      paths: () => [questionBeugel],
      next: () => {
        console.log('asdasd', questionSize)
        if (!this.question.size) {
          this.question.size = {};
        }
        return questionBeugel;
      },
    }
    
    const questionHDMI: IPage = {
      type: 'multible',
      title: 'HDMI', // 2
      headquestion: 'Heeft uw TV een HDMI aansluiting?',
      field: 'HDMI',
      options: [
        {
          image: 'assets/zorgscherm-hdmi.png',
          name: 'Ja',
          value: 'Ja'
        },
        {
          image: 'assets/zorgscherm-no-hdmi.png',
          name: 'Nee',
          value: 'Nee'
        },
        {
          image: 'assets/zorgscherm-what-is-hdmi.png',
          name: 'Ik weet het niet',
          value: 'Ik weet het niet'
        }
      ],
      paths: () => [questionCeiling],
      next: () => {
        if (this.question.HDMI ) {
          return questionCeiling;
        }
      }
    };
    const questionBeugel: IPage = {
      type: 'multible',
      title: 'Beugel',
      headquestion: 'Wat voor tv beugel wilt u?',
      field: 'beugel',
      options: [
        {
          image: 'assets/zorgscherm-beugel-flat.png',
          name: 'Een‘platte’ beugel op de muur (standaard & aanbevolen) (de tv komt strak tegende muur)',
          value: 'plat'
        },
        {
          image: 'assets/zorgscherm-beugel-turnable.png',
          name: 'Een draaibare beugel op de muur (+ € 50 excl. BTW)',
          value: 'turnable'
        },
        {
          image: 'assets/zorgscherm-beugel-ceiling.png',
          name: 'Een plafondbeugel (+ € 50 excl. BTW)',
          value: 'plafond'
        }
      ],
      paths: () => [questionSurface1, questionSurface2],
      next: () => {
        switch (this.question.beugel) {
          case '':
          case undefined:
            return;
          case 'plafond':
            return questionCeiling;
          default:
            return questionSurface1;
        }
      }
    };
    const questionCeiling: IPage = {
      type: 'open',
      title: 'plafond',
      headquestion: 'Hoe hoog is het plafond?',
      field:"ceiling",
      options:[
        "hoogte"
      ],
      paths: () => [questionSystemCeiling],
      next: () => {
        if (!this.question.ceiling) {
          this.question.ceiling = {};
        }
        return questionSystemCeiling;
      },
    }
    const questionSystemCeiling: IPage = {
      type: 'multible',
      title: 'Systeemplafond',
      headquestion: 'Heeft u een systeemplafond bij de tv?',
      subquestion: 'Een systeemplafond is een verlaagd plafond wat bestaat uit een metalen frame waarin de plafondplaten worden gelegd. Dat kan invloed hebben op de installatie van uw Zorgscherm.',
      field: 'systemceiling',
      options: [
        {
          image: 'assets/zorgscherm-systeemplafond-yes.png',
          name: 'Ja',
          value: 'Ja'
        },
        {
          image: 'assets/zorgscherm-systeemplafond-no.png',
          name: 'Nee',
          value: 'Nee'
        },
        {
          image: 'assets/zorgscherm-systeemplafond-dontknow.png',
          name: 'Ik weet het niet',
          value: 'Ik weet het niet'
        }
      ],
      paths: () => [questionPhotoRoom],
      next: () => {
        if(this.question.package === "Basic"){
          if (this.question.systemceiling){
            return questionElectro
          }
        }else if (this.question.systemceiling) {
          return questionPhotoRoom;
        }
      },

    };
    const questionSurface1: IPage = {
      type: 'multible',
      title: 'Ondergrond',
      headquestion: 'Op wat voor type ondergrond komt de tv met beugel te hangen?',
      field: 'surface',
      options: [
        {
          image: 'assets/zorgscherm-muur-steen.png',
          name: 'steen / beton',
          value: 'steen'
        },
        {
          image: 'assets/zorgscherm-muur-gips.png',
          name: 'gips',
          value: 'gips'
        },
        {
          image: 'assets/zorgscherm-muur-hout.png',
          name: 'hout',
          value: 'hout'
        }
      ],
      paths: () => [questionCeiling],
      next: () => {
        if (this.question.surface) {
          return questionCeiling;
        }
      },

    };
    const questionSurface2: IPage = {
      type: 'multible',
      title: 'Ondergrond',
      headquestion: 'Op wat voor type ondergrond komt te tv met beugel te hangen?',
      field: 'surface',
      options: [
        {
          image: 'assets/zorgscherm-plafond-beton.png',
          name: 'steen / beton',
          value: 'steen'
        },
        {
          image: 'assets/zorgscherm-plafond-hout.png',
          name: 'hout',
          value: 'hout'
        }
      ],
      paths: () => [questionPhotoRoom],
      next: () => {
        if (this.question.surface) {
          return questionPhotoRoom;
        }
      },

    };

    const questionElectro: IPage =  { // 5
      type: 'multible',
      title: 'Stopcontact',
      headquestion: 'Heeft u nabij de plek van uw tv of waar deze komt te staan binnen 3 meter een stopcontact?',
      field: 'electro',
      options: [
        {
          image: 'assets/zorgscherm-power-3m.png',
          name: 'Ja',
          value: 'Ja'
        },
        {
          image: 'assets/zorgscherm-no-power-3m.png',
          name: 'Nee',
          value: 'Nee'
        }
      ],
      paths: () => [questionWiredInternet, questionElectroOpen],
      next: () => {
        switch (this.question.electro) {
          case 'Ja':
            return questionWiredInternet;
          case 'Nee':
            return questionElectroOpen;
        }
      }
    };
    const questionElectroOpen: IPage = {
      type: 'open',
      title: 'Waar kunnen we stroom vandaan halen?',
      headquestion: 'Kunt u ons vertellen waar in de buurt van uw tv, of waar deze komt te hangen, een stroompunt is?',
      field: 'electro_open',
      options: [
        'Locatie'
      ],
      paths: () => [questionWiredInternet],
      next: () => {
        if (!this.question.electro_open) {
          this.question.electro_open = {};
        }
        if (this.question.electro_open &&
          this.question.electro_open['Locatie']) {
          return questionWiredInternet;
        }
      }
    };
    const questionWiredInternet: IPage =  { // 6
      type: 'multible',
      title: 'Bedraad internet',
      headquestion: 'Is er in de buurt van uw tv, of waar deze komt te hangen, een vrije internetaansluiting?',
      options: [
        {
          image: 'assets/zorgscherm-network-near.png',
          name: 'Ja binnen 2 meter',
          value: 'Ja binnen 2 meter'
        },
        {
          image: 'assets/zorgscherm-network-lt-15m-no-drill.png',
          name: 'De aansluiting is verder weg (tot 15 meter) maar bereikbaar zonder te boren',
          value: 'De aansluiting is verder weg (tot 15 meter) maar bereikbaar zonder te boren '
        },
        {
          image: 'assets/zorgscherm-network-gt-15m-no-drill.png',
          name: 'De aansluiting is verder weg (15+ meter) maar bereikbaar zonder te boren',
          value: 'De aansluiting is verder weg (15+ meter) maar bereikbaar zonder te boren '
        },
        {
          image: 'assets/zorgscherm-network-gt-15m-drill.png',
          name: 'De aansluiting is verder weg (15+ meter) en er moet geboord worden ',
          value: 'De aansluiting is verder weg (15+ meter) en er moet geboord worden'
        },
        {
          image: 'assets/zorgscherm-network-no.png',
          name: 'De aansluiting is niet nabij en niet bereikbaar',
          value: 'Geen aansluiting'
        }
      ],
      field: 'wiredinternet',
      paths: () => {
        switch (this.question.package) {
          case 'Basic':
            return [questionNetworkType, questionWifiInternet];
          case 'Complete':
            return [questionWifiInternet, questionDrillMaterial];
          default:
            return;
        }
      },
      next: () => {
        switch (this.question.wiredinternet) {
          case 'Ja binnen 2 meter':
          case 'De aansluiting is verder weg (tot 15 meter) maar bereikbaar zonder te boren ':
          case 'De aansluiting is verder weg (15+ meter) maar bereikbaar zonder te boren ':
                return questionNetworkType;
          case 'De aansluiting is verder weg (15+ meter) en er moet geboord worden':
              return questionDrillMaterial;
          case 'Geen aansluiting':
            return questionWifiInternet;
          default:
            return;
        }
      }
    };
    const questionDrillMaterial: IPage = {
      type: 'open',
      title: 'Boren',
      headquestion: 'Kunt u vertellen in welk materiaal geboord moet worden en hoe dik dit ongeveer is?',
      options: ['Materiaal'],
      field: 'drilling',
      paths: () => {

          return [questionNetworkType];
      },
      next: () => {
        if (!this.question.drilling) {
          this.question.drilling = {};
        }
        if (this.question.drilling.Materiaal) {
          return questionNetworkType;
        }
      }
    };
    const questionWifiInternet: IPage = { // 7
      type: 'multible',
      title: 'Wifi',
      headquestion: 'Heeft u een gesloten WiFi netwerk met voldoende signaal aanwezig?',
      subquestion: 'Met gesloten bedoelen wij geen gastennetwerk die zonder wachtwoord toegankelijk is.',
      options: [
        {
          image: 'assets/zorgscherm-wifi.png',
          name: 'Ja',
          value: 'Ja'
        },
        {
          image: 'assets/zorgscherm-wifi-no.png',
          name: 'Nee',
          value: 'Nee'
        }
      ],
      field: 'wifiinternet',
      paths: () => {
            return [questionNetworkType];
      },
      next: () => {
        switch (this.question.wifiinternet) {
          case 'Ja':

            return wifiDetails;
          case 'Nee':
            return result_HardConnection;
          default:
            return;
        }
      }
    };
    const wifiDetails: IPage = {
      type: 'open',
      title: 'WIFI Details',
      field: 'wifiDetails',
      headquestion: 'Wat zijn de login gegevens van het WIFI netwerk?',
      options: ['WIFI naam', 'WIFI password'],
      paths: () => {
          return [questionNetworkType];
      },
      next: () => {
        if (!this.question.wifiDetails) {
          this.question.wifiDetails = {};
        }
        if (this.question.wifiDetails &&
          this.question.wifiDetails['WIFI naam'] &&
          this.question.wifiDetails[ 'WIFI password'] &&
          this.question.wifiDetails[ 'WIFI password'].length >= 8) {
              return questionNetworkType;
        }
        return;
      }

    };

    const questionPhotoRoom: IPage = { // 10
      title: 'Foto\'s',
      type: 'photo',
      field: 'photoroom',
      headquestion: 'Kunt u foto\'s toesturen van de ruimte en plek voor de tv installatie ?',
      paths: () => [questionElectro],
      next: () => {
        return questionElectro;
      }
    };
    const availability: IPage = {
      title: 'Beschikbaarheid',
      type: 'open',
      field: 'availability',
      headquestion: 'Welke dagdelen op welke weekdagen schikken u voor installatie/montage?',
      options: ['Weekdagen', 'Dagdelen'],
      paths: () => [questionNetworkType],
      next: () => {
        if (!this.question.availability) {
          this.question.availability = {};
        }
        return result_succes;
      }
    };

    const result_HardConnection = { // result 1
      type: 'submit',
      title: 'Eindresultaat',
      template: this.resulthardconnection,

    };

    const result_hardElectro =  { // result 2
      type: 'submit',
      title: 'Eindresultaat',
      template: this.resulthardelectro,
    };
    const result_succes = { // result 1
      type: 'submit',
      title: 'Eindresultaat',
      template: this.resultsucces,

    };
    const questionNetworkType: IPage = {
      type: 'multible',
      title: 'Netwerk',
      field: 'admin',
      headquestion: 'Heeft u een klein- of thuisnetwerk voor het internet of is het in beheer van een systeembeheerder?',
      options: [
        {
          image: 'assets/zorgscherm-networktype-home.png',
          name: 'Klein- of thuisnetwerk',
          value: 'Klein- of thuisnetwerk'
        },
        {
          image: 'assets/zorgscherm-network-managed.png',
          name: 'Systeembeheerder',
          value: 'Systeembeheerder'
        }
      ],
      paths: () => [],
      next: () => {
        switch (this.question['admin']) {
          case 'Klein- of thuisnetwerk':
            return informatieSysteem;
          case 'Systeembeheerder':
            return systemAdmin;
        }
      }
    };
    const systemAdmin: IPage = {
      type: 'open',
      title: 'Systeem beheerder',
      field: 'adminDetails',
      headquestion: 'Heeft u voor ons contactgegevens van uw systeembeheerder? ',
      options: [
        'Naam / Bedrijf',
        'Telefoon nummer',
        'Email'
      ],
      paths: () => [informatieSysteem],
      next: () => {
        if (!this.question.adminDetails) {
          this.question.adminDetails = {};
        }
        const adminDetails = this.question['adminDetails'];
        return Boolean(
          adminDetails['Naam / Bedrijf'] &&
          adminDetails['Telefoon nummer'] &&
          adminDetails['Email']
      ) ? informatieSysteem : null;
      }
    };
    const informatieSysteem: IPage = {
      type: 'open',
      title: 'Informatiesysteem',
      field: 'informatieSysteem',
      headquestion: 'Met welk informatiesysteem werkt u?',
      subquestion: 'Hiermee bedoelen wij uw programma voor patiëntenadministratie. Wij werken met verschillende leveranciers samen. Het kan daarom handig zijn dit aan te geven. Het delen van deze informatie is niet verplicht.',
      options: [
        'Informatie systeem',
      ],
      paths: () => [invoiceAdress],
      next: () => {
        if (!this.question.informatieSysteem) {
          this.question.informatieSysteem = {};
        }

        return invoiceAdress;
      }
    };
    const invoiceAdress: IPage = {
      type: 'open',
      title: 'Factuur gegevens',
      field: 'invoice',
      headquestion: 'Wat is uw factuuradres? ',
      options: [
        'Adres',
        'Postcode',
        'Plaats',
        'Email',
        'T.a.v.'
      ],
      paths: () => [],
      next: () => {
        if (!this.question.invoice) {
          this.question.invoice = {};
        }
        const invoiceDetails = this.question['invoice'];
        return Boolean(
          invoiceDetails['Adres'] &&
          invoiceDetails['Postcode'] &&
          invoiceDetails['Plaats'] &&
          invoiceDetails['Email'] &&
          invoiceDetails['T.a.v.']
      ) ? incasso : null;
      }
    };
    const incasso: IPage = {
      type: 'multible',
      title: 'Automatisch incasso',
      field: 'incasso',
      headquestion: 'Wilt u gebruik maken van betaling via automatische incasso?',
      subquestion: 'Wanneer u hier gebruik van wilt maken, ontvangt u van ons een e-mail over hoe u ons hiervoor op een veilige en vertrouwde manier toestemming kunt geven.',
      options: [
        {
          image: 'assets/zorgscherm-incasso-yes.png',
          name: 'Ja',
          value: 'Ja'
        },
        {
          image: 'assets/zorgscherm-incasso-no.png',
          name: 'Nee',
          value: 'Nee'
        }
      ],
      paths: () => [],
      next: () => {
        switch (this.question['incasso']) {
          case 'Ja':
            return aansluiting;
          case 'Nee':
            return aansluiting;
        }
      }
    };
    const aansluiting: IPage = {
      type: 'open',
      title: 'Aansluiting',
      field: 'aansluiting',
      headquestion: 'Indien van toepassing: bent u aangesloten bij een zorgregio, coöperatie of ander samenwerkingsverband?',
      subquestion: 'Wij werken met verschillende organisaties samen waardoor een prijsvoordeel van toepassing kan zijn. Het delen van deze informatie is niet verplicht.',
      options: [
        'Aansluiting',
      ],
      paths: () => [copy],
      next: () => {
        if (!this.question.aansluiting) {
          this.question.aansluiting = {};
        }

        return copy;
      }
    };
    const other: IPage = {
      type: 'open',
      title: 'Overig',
      field: 'other',
      headquestion: 'Zijn er bijzonderheden waar wij rekening mee moeten houden?',
      options: [
        'Bijzonderheden',
      ],
      paths: () => [],
      next: () => {
        if (!this.question.other) {
          this.question.other = {};
        }
        return availability;
      }
    };
    const copy: IPage = {
      type: 'multible',
      title: 'Kopie',
      field: 'copy',
      headquestion: 'Wilt u een kopie van de door u ingevulde vragenlijst ontvangen?',
      subquestion: 'U zult deze via email ontvangen.',
      options: [
        {
          image: '',
          name: 'Ja',
          value: 'Ja'
        },
        {
          image: '',
          name: 'Nee',
          value: 'Nee'
        }
      ],
      paths: () => [],
      next: () => {
        if (this.question.copy == 'Ja') {
          return email;
        }
        else {
          return other;
        }
      }
    };
    const email: IPage = {
      type: 'open',
      title: 'Email',
      field: 'email',
      headquestion: 'Vul hier uw email in om een kopie te ontvangen van de door u ingevulde vragenlijst.',
      options: [
        'email',
      ],
      paths: () => [],
      next: () => {
        console.log(this.question.email)
        if (!this.question.email) {
          this.question.email = {};
        }
        return other
      }
    };
    let question: any = greeting;
    const questions = [question];
    while (question.next && question.next()) {
      question = question.next();
      questions.push(question);
    }
    this.questions =  questions;
    return questions;
  }
  trackBy(i) {
    return i;
  }
  next() {

  }
  submit() {
    const results = this.questions.map(q => {
      return [q.field, this.question[q.field]];
    });
    this.result = `
      <div>
        ${this.questions.map((q: IPage) => {
          let qresult: string;
          switch (q.type) {
            case 'multible':
            case 'custom':
            case undefined:
              qresult = this.question[q.field];
              break;
            case 'photo':
              qresult = !!this.question[q.field]  ? (this.question[q.field] as string[])
              .map(img => `<img src=\'${img}\'/>`)
              .join('') : '';
              break;
            case 'submit':
              return '';
            case 'open':
              if (q.title != 'Welkom') {
                const obj = this.question[q.field] as {[str: string]: string};
                qresult = q.options.map(value => `
                  <h5>${value}</h5>
                  <p>${obj[value]}</p>
                `).join('\n');
              }
              break;
            default:

          }

          if (!qresult) {
            return '';
          }

          return `
            <div>
              <h4>${q.headquestion}</h4>
              <div>${qresult}</div>
            </div>
          `;
      }).join('\n')
        }
      </div>
    `;
        if (this.status === 'incomplete' ) {
          const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          });
          const body = new URLSearchParams();
                body.set('content', this.result);
                body.set('company', this.question.company['bedrijfs naam']);
                if (this.question.copy == 'Ja') {
                  body.set('copy', 'Ja')
                  body.set('email', this.question.email.email)
                }
          this.status = 'sending';
          this.http.post('https://vragenlijst.zorgscherm.nl/api/questions', body.toString(), {headers})
          .subscribe(() => {
            this.status = 'send';
            this.statusChanged.emit('send');
          });
        }
  }

}
