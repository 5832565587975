import { Component, Input, TemplateRef, OnInit, AfterContentInit, AfterViewInit, AfterContentChecked} from '@angular/core';
import { IImageOption } from './app.questions.pictureOption';
export type Paths = () => any[];
export type NextFunction = () => any;
export interface IMultiblePage {
    title: string;
    field: string;
    headquestion: string;
    subquestion?: string;
    type: 'multible';
    options: IImageOption[];
    paths: Paths;
    next: NextFunction;
}
export interface IOpenPage {
    title: string;
    field?: string;
    headquestion: string;
    subquestion?: string;
    type: 'open';
    options?: string[];
    paths: Paths;
    next: NextFunction;
}

export interface IPhotoPage {
    title: string;
    field: string;
    headquestion?: string;
    subquestion?: string;
    type: 'photo';
    paths: Paths;
    next: NextFunction;
}
export interface ICustomPage {
    title: string;
    field: string;
    headquestion: string;
    subquestion?: string;
    type?: 'custom' | undefined;
    template: TemplateRef<any>;
    paths: Paths;
    next: NextFunction;
}
export interface IEndPage {
    type: 'submit';
    title: string;
    heading: string;
    content: string;
}
export type IPage = IMultiblePage | IPhotoPage | IOpenPage | ICustomPage | IEndPage;
@Component({
    selector: 'app-generic-question',
    templateUrl: './app.questions.generic.html',
    styleUrls:  ['./app.questions.generic.css']
})
export class GenericQuestionComponent {
    @Input() page: IPage;
    @Input() question: any;
}
