import { Component, Input} from '@angular/core';
@Component({
    selector: 'app-picture-upload',
    templateUrl: './app.questions.uploadPhotos.html',
    styleUrls:  []
})
export class PictureUploadComponent {
    @Input() questions: any;
    @Input() field: string;
    onChange(event) {
        const reader = new FileReader();
        if (event.target.files && event.target.files[0]) {
            const [file] = event.target.files;
            const img = new Image()
            img.onload = ()=>{
                const ratio = Math.min(640 / img.width, 480 / img.height,1);
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = img.width*ratio;
                canvas.height= img.height*ratio;
                ctx.drawImage(img,0,0,canvas.width,canvas.height)
                this.questions[this.field].push(canvas.toDataURL());
                
            }
        

            reader.readAsDataURL(file);
            reader.onload = () => {
                if (!this.questions[this.field]) {
                    this.questions[this.field] = [];
                }
                img.src = reader.result
         
            };

        }
    }
    removePhoto(nr) {
        if (this.questions[this.field]) {
            this.questions[this.field].splice(nr, 1);
        }
    }


}
