import { Component, Input} from '@angular/core';
export interface IImageOption {
image: string;
name: string;
value: string;
}

@Component({
    selector: 'app-picture-option',
    templateUrl: './app.questions.pictureOption.html',
    styleUrls:  ['./app.questions.pictureOption.css']
})
export class PictureOptionComponent {
    @Input() image: string;
    @Input() name: string;
    @Input() description: string;
    @Input() value: string;
    @Input() questions: any;
    @Input() field: string;
}
