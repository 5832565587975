import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import {PageFilter} from './pageFilter/app.pageFilter';
import { FormsModule } from '@angular/forms';
import {PictureOptionComponent} from './questions/app.questions.pictureOption';
import { PictureUploadComponent } from './questions/app.questions.uploadPhotos';
import { GenericQuestionComponent } from './questions/app.questions.generic';
import { HttpModule } from '@angular/http';

@NgModule({
  declarations: [
    AppComponent,
    PictureOptionComponent,
    PictureUploadComponent,
    GenericQuestionComponent,
    PageFilter
  ],
  imports: [
    BrowserModule,
    HttpModule,
    NgbModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
