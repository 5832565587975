import {Pipe, PipeTransform, Injectable} from '@angular/core';
@Pipe({
    name: 'filter'
})
@Injectable()
export class PageFilter implements PipeTransform {
    transform(items: {conditions(): boolean}[]) {
        if (!items) {
            return [];
        }

        return items.filter(item => item.conditions() || true);
    }
}
